@font-face {
  font-family: Lobster;
  src: url(/src/fonts/Lobster.otf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(/src/fonts/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansBold;
  src: url(/src/fonts/OpenSans-Bold.ttf);
}

@font-face {
  font-family: OpenSansSemiBold;
  src: url(/src/fonts/OpenSans-SemiBold.ttf);
}

@font-face {
  font-family: OpenSansExtraBold;
  src: url(/src/fonts/OpenSans-ExtraBold.ttf);
}

.App {
  text-align: center;
  font-family: OpenSansRegular;
  background-color: rgb(236, 236, 236);
}

.card-deck {
  margin-left: auto;
  margin-right: auto;
}

.card {
  width: 200px;
}

.producttitle {
  font-size: 16px;
  min-height: 58px;
  color: rgb(36, 36, 36);
  font-weight: bolder;
  text-transform: uppercase;
}

.card-text {
  height: 62px !important;
  overflow: hidden;
}

.btn-product {
  border: 1px solid grey;
  width: 100%;
  max-width: 200px;
  transition: all ease 0.5s;
}

.btn-product:hover {
  border: 1px solid rgb(0, 0, 0);
  width: 90%;
  color: rgb(0, 0, 0);
  transition: all ease 0.5s;
  font-weight: bold;
}

.footer {
  background-color: rgb(51, 51, 51);
  color: white;
}

.footertext {
  font-size: 12px;
  max-width: 50%;
  max-width: 400px;
  color: #747474;
}

.footertop {
  padding-bottom: 50px;
}

.form-group {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.copyright {
  background-color: rgb(37, 37, 37);
  color: #747474;
  margin: 0;
  padding: 0;
  padding-top: 16px;
  padding-bottom: 3px;
  font-size: 11px;
}

.carousel-indicators>li {
  border-radius: 50%;
  width: 13px;
  height: 13px;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background-image: url("components/gfx/chevron.png");
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  filter: invert(100%);
}

.carousel-control-prev-icon {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.carousel-caption {
  bottom: 40%;
  font-family: Lobster;
  font-size: 3.6rem !important;
  font-weight: 400;
}

.LobsterText {
  font-family: Lobster;
}

.lorem {
  color: #3f3f3f;
  font-size: 13px;
  max-width: 760px;
  margin: auto;
}

.lorem2 {
  color: #3f3f3f;
  font-size: 11px;
  margin-top: -6%;
  max-width: 190px;
  margin-left: auto;
  margin-right: auto;
}

.IntroImg {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  margin-bottom: 10%;
}

h5 {
  font-weight: bolder;
  text-transform: uppercase;
  font-size: 14px;
}

.NewsLetter {
  min-height: 300px;
  background-image: url("components/gfx/newsletterbg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: unset;
}

.NewsLetterText {
  color: white;
  text-align: left;
  font-weight: lighter;
}

.newsletterinput {
  border: none;
  height: 30px;
  width: 400px;
  font-size: 12px;
  padding-left: 10px;
}

.newsletterinput:focus {
  border: none;
  outline: none;
  
}

.newslettericon {
  height: 30px;
  background-color: #bcc8d4;
  color: white;
  padding: 7px;
  padding-bottom: 10px;
  padding-top: 3px;
}

.newsletterbutton {
  border: none;
  background-color: #607684;
  text-transform: uppercase;
  height: 30px;
  width: 100px;
  font-size: 10px;
  color: white;
}

.ChevronTop {
  position: absolute;
  top: -105%;
  left: 48.5%;
  transform: rotate(270deg);
  width: 40px;
  height: auto;
  background-color: white;
  border-radius: 50%;
  padding: 7px;
  transition: all ease 1.5;
}

.ChevronTop:hover {
  background-color: rgb(189, 189, 189);
  transition: all ease 1.5;
}

.productnavigation {
  background-color: rgb(255, 255, 255);
  border: solid 1px rgb(122, 122, 122);
  font-size: 11px;
}

.imgcontainer {
  float: left;
  margin-right: 10px;
}

.produkttitel {
  font-family: OpenSansSemiBold;
  text-transform: uppercase;
}

.prodkat {
  color: #747474;
}

.ingredienser li {
  list-style: none;
  border: #747474 1px solid;
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 3px;
}

.sidenavlist li {
 list-style: none;
 text-transform: uppercase;
 margin-bottom: 20px;
 color: #747474;
 font-size: 15px;
}

.likebutton {
  font-size: 12px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.heart {
  color: red;
  font-size: 14px;
  font-family: OpenSansSemiBold;
}

.KategoriKnap {
  border: none;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #747474;
  font-size: 15px;
  padding-left: 0;
}

.KategoriKnap:hover {
  color: black;
}

.KategoriKnap:focus {
  color: black;
  outline: none;
  border: none;
  font-weight: bold;
}

.contactsubmitbtn {
  float: right;
  width: 150px;
  border-radius: 0;
}

.grey {
  color: #747474;
}


.SliderElement {
  margin-top: -10%;
  z-index: 2;
}

.fileContainer {
  max-width: 400px;
  height: auto;
}

.uploadPictureContainer {
  width: 80% !important;
  height: auto;
}

.whitebackground {
  background-color: white;
  border: solid 1px grey;
}

.commentheadline {
  font-family: OpenSansSemiBold;
  font-size: 18px;
  text-align: left;
  padding-top: 7px;
}

.commentform {
  width: 100%;
}

.kommentarinput {
  width: 80%;
  margin: 0;
  padding-left: 14px;
  border: none;
  outline: none;
  font-size: 14px;
}

.commentform button {
  width: 15%;
  height: 50px;
  color: white;
  background-color: #617685;
  border: none;
}

.commentform button:active {
  border: none;
  outline: none;
}

.formIcon {
  width: 5%;
  height: 24px;
}

.hidden {
  display: none;
  width: 0;
  height: 0;
}


@media only screen and (max-width: 575px) {
  .SliderElement, .logotitle {
    display: none;
  }
  .sidenavlist li {
    font-size: 10px;
  }
}
